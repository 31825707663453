import {
  SELECT_SERVICE,
  SELECT_PROVIDER,
  SKIP_PROVIDER,
  PROVIDER_REVIEWS_REQUEST,
  PROVIDER_REVIEWS_FULFILLED,
  PROVIDER_REVIEWS_REJECTED,
  ACTIVE_PAGE_REVIEW,
  SELECT_MORNING,
  SELECT_NOON,
  SELECT_EVENING,
  SELECT_DATE_TO,
  SELECT_DATE_FROM,
  FETCH_CLINIC_FULFILLED,
  FETCH_OPENING_TIME,
  FETCH_OPENING_TIME_FULFILLED,
  FETCH_OPENING_TIME_REJECTED,
  RESET_OPENINGS,
  SELECT_TIME,
  FETCH_SEND_APPOINTMENT,
  FETCH_SEND_APPOINTMENT_FULFILLED,
  FETCH_SEND_APPOINTMENT_REJECTED,
  SET_DAYS_VIEW,
  USER_LOGOUT_SUCCESS,
  SELECT_SLOT_TIME,
  APP_REQ_ERR_MSG_HIDE,
  CHANGE_PATIENT_ID,
  RESET_APPT_REQUEST,
  SET_OFFICE_LOCATION,
  SET_PATIENT_REQUEST,
  FETCH_SEND_APPOINTMENT_FULFILLED_INACTIVE_PATIENT,
  USER_AUTH_SUCCESS,
} from '../constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_CLINIC_FULFILLED: {
      return {
        ...state,
        request: {
          ...state.request,
          hashBid: action.payload.clinicId,
        },
      };
    }
    case USER_AUTH_SUCCESS: {
      return {
        ...state,
        request: {
          ...state.request,
          hashBid: action.payload.clinicId,
        },
      };
    }
    case SET_OFFICE_LOCATION: {
      return {
        ...state,
        request: {
          ...state.request,
          hashBid: action.payload,
        },
      };
    }
    /*eslint-disable*/
    case SELECT_SERVICE: {
      return {
        ...state,
        selectedService: action.payload.service,
        selectedServiceId: parseInt(action.payload.service.id, 0),
        selectedServiceProviders: null,
        selectedProviderForReview: null,
        activePageReview: null,
        request: {
          ...state.request,
          serviceId: parseInt(action.payload.service.id, 0),
          providerId: null,
          providerPmsId: null,
          providerName: null,
          dateFrom: null,
          dateTo: null,
          preferredMorning: true,
          preferredNoon: true,
          preferredEvening: true,
          serviceDuration: action.payload.service.duration,
          serviceScheduleLimit: action.payload.service.scheduleLimit,
          serviceName: action.payload.service.description,
          activePatient: true,
        },
        openings: [],
        selectedTime: null,
        comment: '',
        appointmentCreatedID: null,
        patientEmail: '',
        confirmedMsg: false,
      };
    }

    case RESET_APPT_REQUEST: {
      return {
        ...state,
        selectedService: null,
        selectedServiceId: null,
        selectedServiceProviders: null,
        selectedProviderForReview: null,
        activePageReview: null,
        selectedProviderId: null,
        request: {
          ...state.request,
          serviceId: null,
          providerId: null,
          providerPmsId: null,
          providerName: null,
          dateFrom: null,
          dateTo: null,
          preferredMorning: false,
          preferredNoon: false,
          preferredEvening: false,
          serviceDuration: null,
          serviceScheduleLimit: null,
          serviceName: null,
          hashBid: null,
          activePatient: true,
        },
        openings: [],
        selectedTime: null,
        comment: '',
        appointmentCreatedID: null,
        patientEmail: '',
        confirmedMsg: false,
      };
    }

    case USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        selectedService: {},
        selectedServiceId: null,
        selectedServiceProviders: [],
        selectedProviderForReview: null,
        activePageReview: null,
        request: {
          ...state.request,
          serviceId: null,
          providerId: null,
          providerPmsId: null,
          dateFrom: null,
          dateTo: null,
          preferredMorning: false,
          preferredNoon: false,
          preferredEvening: false,
          serviceDuration: null,
          serviceScheduleLimit: null,
          hashBid: null,
          activePatient: true,
        },
        openings: [],
        selectedTime: null,
        comment: '',
        appointmentCreatedID: null,
        patientEmail: '',
        confirmedMsg: false,
      };
    }

    case PROVIDER_REVIEWS_REQUEST: {
      return {
        ...state,
        reviewFetching: true,
        reviewFetched: false,
        selectedProviderForReview: action.payload,
      };
    }

    case PROVIDER_REVIEWS_FULFILLED: {
      return {
        ...state,
        reviewFetching: false,
        reviewFetched: true,
        selectedProviderReviews: action.payload,
      };
    }

    case ACTIVE_PAGE_REVIEW: {
      return {
        ...state,
        activePageReview: action.payload,
      };
    }

    case PROVIDER_REVIEWS_REJECTED: {
      return {
        ...state,
        reviewFetching: false,
        reviewFetched: false,
      };
    }

    case SKIP_PROVIDER: {
      return {
        ...state,
        skipProvider: action.payload,
      };
    }

    case SELECT_PROVIDER: {
      return {
        ...state,
        selectedProviderId: action.payload.id,
        request: {
          ...state.request,
          providerId: action.payload.id,
          providerPmsId: action.payload.pmsId,
          providerName: action.payload.name,
        },
      };
    }
    case SELECT_MORNING: {
      return {
        ...state,
        request: {
          ...state.request,
          preferredMorning: action.payload,
        },
      };
    }

    case SELECT_NOON: {
      return {
        ...state,
        request: {
          ...state.request,
          preferredNoon: action.payload,
        },
      };
    }

    case SELECT_EVENING: {
      return {
        ...state,
        request: {
          ...state.request,
          preferredEvening: action.payload,
        },
      };
    }

    case SELECT_DATE_TO: {
      return {
        ...state,
        request: {
          ...state.request,
          dateTo: action.payload,
        },
      };
    }

    case SELECT_DATE_FROM: {
      return {
        ...state,
        request: {
          ...state.request,
          dateFrom: action.payload,
        },
      };
    }

    case FETCH_OPENING_TIME: {
      return {
        ...state,
        fetchingTime: true,
        fetchedTime: false,
      };
    }

    case FETCH_OPENING_TIME_FULFILLED: {
      return {
        ...state,
        fetchingTime: false,
        fetchedTime: true,
        openings: action.payload,
      };
    }

    case FETCH_OPENING_TIME_REJECTED: {
      return {
        ...state,
        fetchingTime: false,
        fetchedTime: false,
        errors: action.payload,
      };
    }

    case SET_DAYS_VIEW: {
      return {
        ...state,
        count: action.payload,
      };
    }

    case SELECT_TIME: {
      return {
        ...state,
        selectedTime: action.payload,
      };
    }

    case SELECT_SLOT_TIME: {
      return {
        ...state,
        selectedTime: action.payload.time,
        slotId: action.payload.slotId,
        locationId: action.payload.locationId,
      };
    }

    case FETCH_SEND_APPOINTMENT: {
      return {
        ...state,
        fetchingSend: true,
        fetchedSend: false,
      };
    }

    case FETCH_SEND_APPOINTMENT_FULFILLED: {
      return {
        ...state,
        fetchingSend: false,
        fetchedSend: true,
        comment: action.payload.comment,
        confirmedMsg: action.payload.approveRequested,
      };
    }

    case FETCH_SEND_APPOINTMENT_FULFILLED_INACTIVE_PATIENT: {
      return {
        ...state,
        fetchingSend: false,
        fetchedSend: true,
        request: {
          ...state.request,
          activePatient: false,
        },
        comment: action.payload.comment,
      };
    }

    case FETCH_SEND_APPOINTMENT_REJECTED: {
      return {
        ...state,
        fetchingSend: false,
        fetchedSend: false,
        errors: action.payload,
        appReqErrMsgModal: true,
      };
    }

    case APP_REQ_ERR_MSG_HIDE: {
      return {
        ...state,
        appReqErrMsgModal: false,
      };
    }

    case CHANGE_PATIENT_ID: {
      return {
        ...state,
        request: {
          ...state.request,
          patientId: action.payload.patientId,
          patientPmsId: action.payload.patientPmsId,
        },
      };
    }

    case SET_PATIENT_REQUEST: {
      return {
        ...state,
        request: {
          ...state.request,
          patientId: action.payload,
        },
      };
    }

    case RESET_OPENINGS:
      return {
        ...state,
        openings: [], // Clear the openings array
      };

    default:
      return state;
  }
};
