import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import StepHeader from './AppointmentStepsHeader';
import '../../assests/sass/partials/_classes.scss';
import StepsButton from './StepsButton';
import AppointmentStepsHeaderNav from './AppointmentStepsHeaderNav';
import {
  selectDateTo,
  selectDateFrom,
  getOpeningTimes, resetOpenings,
} from '../../actions/appointmentActions';
import Results from './Time';

function DateAndTime({
  dispatch,
  themeColor,
  request,
  requestService,
  history,
  token,
  fetchingTime,
}) {
  const applyButtonStyle = {
    background: themeColor,
    color: '#fff',
    width: '100px',
    borderRadius: '20px',
  };

  const [fromValue, setFromValue] = useState('');
  const [toValue, setToValue] = useState('');

  useEffect(() => {
    dispatch(resetOpenings());
  }, [dispatch]);

  const handleFromChange = (e) => {
    setFromValue(e.target.value);
    const date = moment(e.target.value, 'YYYY-MM-DD');
    if (date.isValid()) {
      dispatch(selectDateFrom(date));
    }
  };

  const handleToChange = (e) => {
    sessionStorage.setItem('pressed', 'false');
    setToValue(e.target.value);
    const date = moment(e.target.value, 'YYYY-MM-DD');
    if (date.isValid()) {
      dispatch(selectDateTo(date));
    }
  };

  const applyOpenings = (e) => {
    e.preventDefault();
    if (fromValue && toValue && moment(fromValue).isBefore(toValue)) {
      sessionStorage.setItem('pressed', 'true');
      dispatch(getOpeningTimes(request, token, history));
    } else {
      toast.warning('Start Date must be before End Date');
    }
  };

  const _ga = sessionStorage.getItem('_ga') ? sessionStorage.getItem('_ga') : '';

  if (!requestService.serviceId) {
    return <Redirect to={`/services${_ga}`} />;
  }
  if (!request.providerId) {
    return <Redirect to={`/providers${_ga}`} />;
  }

  return (
    <div>
      <div className="steps-container">
        <Row className="g-0 flex-shrink-0">
          <Col md={3} lg={3} sm={12} className="appointment-steps-header-nav">
            <AppointmentStepsHeaderNav />
          </Col>
          <Col md={9} lg={9} sm={12}>
            <div className="main-content">
              <StepHeader
                title="Select a day and time"
                description="Please select a range of days and times convenient for you"
              />
              <Row className="mt-5">
                <Col md={12} lg={4}>
                  <div className="me-4">
                    <Row className="justify-content-center align-items-center">
                      <Col sm={12} md={12} lg={12} className="mb-2">
                        <p className="m-0">Select your date range</p>
                      </Col>
                      <Col sm={12}>
                        <form className="dates d-flex g-2 justify-content-space-between align-items-center">
                          <input
                            type="date"
                            value={fromValue}
                            onChange={handleFromChange}
                            className="input form-control"
                            placeholder="From Date"
                            style={{ fontSize: '14px' }}
                          />
                          {' – '}
                          <input
                            type="date"
                            value={toValue}
                            onChange={handleToChange}
                            className="input form-control"
                            placeholder="To Date"
                            style={{ fontSize: '14px' }}
                          />
                        </form>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-2">
                      <button
                        onClick={() => {
                          setFromValue('');
                          setToValue('');
                          dispatch(selectDateFrom(null));
                          dispatch(selectDateTo(null));
                        }}
                        type="button"
                        className="btn me-3"
                        style={{ cursor: 'pointer', color: themeColor }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn"
                        style={applyButtonStyle}
                        type="submit"
                        onClick={applyOpenings}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12}>
                  <div className="h-100 d-flex justify-content-center align-items-stretch">
                    {fetchingTime ? (
                      <div style={{ color: themeColor }}>
                        <div className="fancy-spinner">
                          <div className="ring" />
                          <div className="ring" />
                          <div className="dot" />
                        </div>
                        <p style={{
                          color: themeColor, padding: '0 20%', fontWeight: 'bold', fontSize: '1rem', marginTop: '20px', textAlign: 'center',
                        }}
                        >
                          Searching available appointments
                        </p>
                      </div>
                    ) : <Results />}
                  </div>
                </Col>
              </Row>
              <StepsButton />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

DateAndTime.propTypes = {
  dispatch: PropTypes.func.isRequired,
  themeColor: PropTypes.string.isRequired,
  request: PropTypes.object.isRequired,
  requestService: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  fetchingTime: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  request: state.appointmentReducer.request,
  requestService: state.serviceReducer.request,
  token: state.userReducer.token,
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fetchingTime: state.appointmentReducer.fetchingTime,
});

export default withRouter(connect(mapStateToProps)(DateAndTime));
